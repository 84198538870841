exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookies-mdx": () => import("./../../../src/pages/cookies.mdx" /* webpackChunkName: "component---src-pages-cookies-mdx" */),
  "component---src-pages-en-gb-about-index-mdx": () => import("./../../../src/pages/en-gb/about/index.mdx" /* webpackChunkName: "component---src-pages-en-gb-about-index-mdx" */),
  "component---src-pages-en-gb-index-tsx": () => import("./../../../src/pages/en-gb/index.tsx" /* webpackChunkName: "component---src-pages-en-gb-index-tsx" */),
  "component---src-pages-en-gb-reviews-index-tsx": () => import("./../../../src/pages/en-gb/reviews/index.tsx" /* webpackChunkName: "component---src-pages-en-gb-reviews-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-kroo-personal-bank-account-current-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/kroo/personal/bank-account/current/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-kroo-personal-bank-account-current-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-monzo-personal-bank-account-current-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/monzo/personal/bank-account/current/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-monzo-personal-bank-account-current-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-monzo-personal-bank-account-plus-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/monzo/personal/bank-account/plus/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-monzo-personal-bank-account-plus-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-monzo-personal-bank-account-premium-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/monzo/personal/bank-account/premium/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-monzo-personal-bank-account-premium-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-metal-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/revolut/personal/bank-account/metal/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-metal-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-plus-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/revolut/personal/bank-account/plus/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-plus-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-premium-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/revolut/personal/bank-account/premium/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-premium-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-standard-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/revolut/personal/bank-account/standard/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-standard-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-ultra-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductReviewTemplate/PersonalBankAccountProductReviewTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/review/revolut/personal/bank-account/ultra/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-review-template-personal-bank-account-product-review-template-tsx-content-file-path-src-content-review-revolut-personal-bank-account-ultra-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-basic-bank-accounts-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductToplistTemplate/PersonalBankAccountProductToplistTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/toplist/personal/bank-accounts/basic-bank-accounts/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-basic-bank-accounts-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-current-accounts-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductToplistTemplate/PersonalBankAccountProductToplistTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/toplist/personal/bank-accounts/current-accounts/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-current-accounts-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-joint-bank-accounts-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductToplistTemplate/PersonalBankAccountProductToplistTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/toplist/personal/bank-accounts/joint-bank-accounts/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-joint-bank-accounts-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-kids-bank-accounts-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductToplistTemplate/PersonalBankAccountProductToplistTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/toplist/personal/bank-accounts/kids-bank-accounts/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-kids-bank-accounts-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-packaged-bank-accounts-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductToplistTemplate/PersonalBankAccountProductToplistTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/toplist/personal/bank-accounts/packaged-bank-accounts/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-packaged-bank-accounts-en-gb-mdx" */),
  "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-teen-bank-accounts-en-gb-mdx": () => import("./../../../src/templates/PersonalBankAccountProductToplistTemplate/PersonalBankAccountProductToplistTemplate.tsx?__contentFilePath=/opt/build/repo/src/content/toplist/personal/bank-accounts/teen-bank-accounts/en.[GB].mdx" /* webpackChunkName: "component---src-templates-personal-bank-account-product-toplist-template-personal-bank-account-product-toplist-template-tsx-content-file-path-src-content-toplist-personal-bank-accounts-teen-bank-accounts-en-gb-mdx" */)
}

