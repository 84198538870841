// eslint-disable-next-line
// eslint-disable-next-line
import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import "./src/styles/fonts.css"
import "./src/styles/reset.css"
import { ThemeProvider } from "./src/styles/theme-provider"
import "./src/styles/global.css"
import "./src/styles/prism/prism-themes.css"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
